import usFlag from '../assets/images/us.svg';
import itlayFlag from '../assets/images/itlay.png';

export const LANGUAGE_DATA = [
  {
    img: usFlag,
    language: 'English',
    value: 'en',
  },
  {
    img: itlayFlag,
    language: 'Italian',
    value: 'it',
  },
];
export const RESELLER_TYPES = [
  { label: 'Standard', value: 'standard' },
  { label: 'Basic', value: 'basic' },
  { label: 'Premium', value: 'premium' },
];
export const SERVICE_TYPES = [
  { label: 'Typing Courses', value: 'courses' },
  { label: 'Safety Courses Typing', value: 'safety' },
  { label: 'Eipass Typing', value: 'eipass' },
];

export const GENDER_TYPES = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
];

export const SESSION_INFO_INSTRUCTIONS = [
  {
    label: 'GUIDA ID CERT',
    value:
      'A seguito del vostro caricamento sul gestionale, il candidato verrà profilato e riceverà un’e-mail che dovrà convalidare cliccando “Convalida”. Successivamente sarà iscritto all’esame. Il giorno dell’esame si dovrà collegare sul sito: idcert.io, inserire le credenziali arrivate per e-mail (username: la loro e-mail, password: visibile nella mail).Il candidato deve essere in possesso di un computer con videocamera. L’esame prevede un’identificazione da parte del docente che guiderà lo svolgimento dell’esame. L’esame è a risposta multipla e la durata è di circa 30 minuti. Terminato l’esame riceveranno l’attestato per e-mail',
  },
  {
    label: 'GUIDA EIPASS 7 MODULI',
    value:
      'A seguito del vostro caricamento sul gestionale, il candidato verrà profilato e riceverà un’e-mail che dovrà convalidare cliccando “Convalida”. Una volta convalidata l’e-mail si creerà una sessione d’esame. Qualora decideste di svolgere l’esame senza tutor, potrete scegliere la data dell’esame indicandola nel calendario del gestionale. Terminato l’esame il candidato potrà scaricare il Libretto Digitale dalla piattaforma inserendo le credenziali',
  },
  {
    label: 'GUIDA EIPASS STANDARD',
    value: `Per sostenere l’esame di Eipass standard il candidato dovrà già essere in possesso dell’ Eipass 7 Moduli. E’necessario compilare il modulo tramite il seguente link:\n\nhttps://didasko.eipass.com/reg_online/index_new.php?eipassfile=iscriz_st.php&t=7W\n\nDopo averlo compilato riceverà dopo circa 5 giorni un codice che dovranno comunicarci per poter creare la sessione.`,
    // <p>
    //   Per sostenere l’esame di Eipass standard il candidato dovrà già essere in possesso dell’ Eipass 7 Moduli. E’
    //   necessario compilare il modulo tramite il seguente link:&nbsp;
    //   <a href="https://didasko.eipass.com/reg_online/index_new.php?eipassfile=iscriz_st.php&t=7W" target="__blank">
    //     https://didasko.eipass.com/reg_online/index_new.php?eipassfile=iscriz_st.php&t=7W
    //   </a>
    //   &nbsp;Dopo averlo compilato riceverà dopo circa 5 giorni un codice che dovranno comunicarci per poter creare la
    //   sessione.
    // </p>
  },
  {
    label: 'GUIDA  CERT. INFORMATICHE (LIM, TABLET, CODING, ANIMATORE DIGITALE, CYBERBULLISMO)',
    value:
      'A seguito del vostro caricamento sul gestionale, il candidato verrà profilato e potrà prenotare l’esame in piattaforma Webomnia.it\n Inserendo le credenziali:\n\nUsername: ST_ e attaccato il proprio Cod. fiscale in MAIUSCOLO\nPass: 123456\n\nDovranno cliccare su CORSI ED ESAMI e successivamente su SESSIONI ESAMI. Si visualizzeranno le date e cliccando sull’omino rosso, compare una finestra con il nome del corso e l’orario dell’esame.\nIl giorno dell’esame i candidati si dovranno collegare in piattaforma Iumna.org, cliccare sul corso desiderato (es. Lim) con le credenziali indicate sopra ed effettuato l’accesso dovranno cliccare su prova finale ed inserire il codice che sarà stato fornito da noi.\nAl termine dell’esame il candidato potrà scaricare l’attestato dalla piattaforma',
  },
  {
    label: 'GUIDA DATTILOGRAFIA',
    value:
      'A seguito del vostro caricamento sul gestionale, vi forniremo un codice che il candidato dovrà utilizzare in questa piattaforma: https://formazione.job-house.it/\n\nEffettuato l’accesso il candidato dovrà inserire il codice fornito da noi dopo aver cliccato l’icona del carrello. Dovrà inserire i dati anagrafici, sceglierà una password che dovrà ricordare e potrà avere accesso all’esame. Una volta sostenuto l’esame uscire dalla piattaforma e rientrare dopo 20 minuti per poter scaricare il certificato con la data d’emissione. Se verrà scaricato prima del tempo sul certificato non sarà visibile la data dell’esame.',
  },
  {
    label: 'GUIDA PEKIT',
    value:
      'A seguito del vostro caricamento sul gestionale, il candidato riceverà la mail che avrete fornito. Si ricorda che la mail deve essere quella del candidato. Entro 7 giorni lavorativi il candidato riceverà l’attestato.',
  },
];

export const COURSE_TYPES = [
  {
    label: 'Corso',
    value: 'course',
  },
  {
    label: 'Diploma',
    value: 'diploma',
  },
  {
    label: 'Master',
    value: 'master',
  },
  {
    label: 'CLIL',
    value: 'clil',
  },
];
